import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
  ShareIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // Import useParams at the top


const VProductPage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [chemicals, setChemicals] = useState([]);
  const [crops, setCrops] = useState([]); // To hold the list of crops
  const [selectedCrops, setSelectedCrops] = useState([]); // To hold the selected crops
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [isChemicalModalOpen, setIsChemicalModalOpen] = useState(false);
  const [newBrand, setNewBrand] = useState('');
  const [newChemical, setNewChemical] = useState('');
  const [chemicalSearch, setChemicalSearch] = useState('');
  const navigate = useNavigate(); // For generating share URLs
  const { vendorId } = useParams(); // Extract vendorId from the URL params

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    brand: '',
    packing_sizes: [{ size: '', price: '', discount_price: '', stock: '' }], // Initialize with one empty packing size
    chemicals: [],
    images: null,
    recommended_for: [],
  });


  const API_BASE_URL = '/api';
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Token ${localStorage.getItem('authToken')}`,
    },
  });

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const [productRes, categoryRes, brandRes, chemicalRes, cropRes] = await Promise.all([
        axiosInstance.get('/products/products/'),
        axiosInstance.get('/products/categories/'),
        axiosInstance.get('/products/brands/'),
        axiosInstance.get('/products/chemicals/'),
        axiosInstance.get('/crop/crops/'),
      ]);
      setProducts(productRes.data);
      setCategories(categoryRes.data);
      setBrands(brandRes.data);
      setChemicals(chemicalRes.data);
      setCrops(cropRes.data);  // Store the crops data

    } catch (error) {
      console.error('Error fetching initial data:', error);
      toast.error('Failed to load data.');
    }
  };

  const handleShareVendorPortfolio = () => {
    // Check if there are products and grab the vendorId from the first product
    if (products.length > 0 && products[0].vendor) {
      const vendorId = products[0].vendor.id; // Get vendor ID from the product data
      const vendorUrl = `${window.location.origin}/vendor/${vendorId}`;
      
      if (navigator.share) {
        navigator.share({
          title: 'Check out this vendor on our platform!',
          url: vendorUrl,
        }).catch((error) => toast.error('Sharing failed.'));
      } else {
        navigator.clipboard.writeText(vendorUrl);
        toast.success('Vendor URL copied to clipboard!');
      }
    } else {
      toast.error('Vendor information not available.');
    }
  };
  
  

  const handleShareProduct = (product) => {
    const productUrl = `${window.location.origin}/products/${product.id}`;
    if (navigator.share) {
      navigator.share({
        title: `Check out this product: ${product.name}`,
        url: productUrl,
      }).catch((error) => toast.error('Sharing failed.'));
    } else {
      navigator.clipboard.writeText(productUrl);
      toast.success('Product URL copied to clipboard!');
    }
  };

  const openAddModal = () => {
    setIsEditing(false);
    setSelectedProduct(null);
    setFormData({
      name: '',
      description: '',
      category: '',
      brand: '',
      packing_sizes: [{ size: '', price: '', discount_price: '', stock: '' }], // Initialize with one empty packing size
      chemicals: [],
      images: null,
    });
    setIsModalOpen(true);
  };

  const openEditModal = (product) => {
    setIsEditing(true);
    setSelectedProduct(product);
    setFormData({
      name: product.name,
      description: product.description,
      category: product.category.id,
      brand: product.brand.id,
      packing_sizes: product.packing_sizes.map((ps) => ({
        size: ps.size,
        price: ps.price,
        discount_price: ps.discount_price || '',
        stock: ps.stock,
      })),
      chemicals: product.chemicals.map((chem) => chem.id),
      images: null,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const closeBrandModal = () => {
    setIsBrandModalOpen(false);
    setNewBrand('');
  };

  const closeChemicalModal = () => {
    setIsChemicalModalOpen(false);
    setNewChemical('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCropChange = (e) => {
    const options = e.target.options;
    const selected = [];
  
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        selected.push(parseInt(options[i].value, 10));  // Ensure that each crop ID is an integer
      }
    }
  
    setSelectedCrops(selected);
  };
  

  const handleChemicalChange = (e) => {
    const options = e.target.options;
    const selectedChemicals = Array.from(options)
      .filter(option => option.selected)
      .map(option => parseInt(option.value, 10));
 
    setFormData({ ...formData, chemicals: selectedChemicals });
 };
 


 const handlePackingSizeChange = (index, e) => {
  const { name, value } = e.target;
  const updatedPackingSizes = [...formData.packing_sizes];
  updatedPackingSizes[index][name] = value;
  setFormData({ ...formData, packing_sizes: updatedPackingSizes });
};


  const addPackingSize = () => {
    setFormData({
      ...formData,
      packing_sizes: [...formData.packing_sizes, { size: '', price: '', discount_price: '', stock: '' }],
    });
  };

  const removePackingSize = (index) => {
    const updatedPackingSizes = formData.packing_sizes.filter((_, i) => i !== index);
    setFormData({ ...formData, packing_sizes: updatedPackingSizes });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, images: e.target.files });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.description || !formData.category || !formData.brand || formData.packing_sizes.length === 0 ||
      formData.packing_sizes.some(ps => !ps.size || !ps.price || !ps.stock) ||
      !formData.images || formData.images.length === 0) {
        toast.error('Please fill in all required fields, including name, description, category, brand, packing sizes, and at least one image.');
        return;
    }

    try {
      console.log('Packing Sizes:', formData.packing_sizes);
      console.log('Chemicals:', formData.chemicals);
      console.log('Selected Crops:', selectedCrops);

      const submitData = new FormData();
      submitData.append('name', formData.name);
      submitData.append('description', formData.description);
      submitData.append('category', formData.category);
      submitData.append('brand', formData.brand);
      submitData.append('packing_sizes', JSON.stringify(formData.packing_sizes)); // Serialize packing sizes
      if (selectedCrops && Array.isArray(selectedCrops)) {
        selectedCrops.forEach(cropId => {
            submitData.append('recommended_for[]', cropId);
        });
    }
    
    
      formData.chemicals.forEach((chemId) => submitData.append('chemicals[]', chemId));
      if (formData.images) {
        Array.from(formData.images).forEach((file) => submitData.append('images', file));
      }
      console.log('FormData:', formData);


      if (isEditing && selectedProduct) {
        await axiosInstance.put(`/products/products/${selectedProduct.id}/`, submitData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        toast.success('Product updated successfully.');
        setProducts((prevProducts) =>
          prevProducts.map((prod) => (prod.id === selectedProduct.id ? { ...prod, ...formData } : prod))
        );
      } else {
        const response = await axiosInstance.post('/products/products/', submitData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        toast.success('Product added successfully.');
        setProducts((prevProducts) => [...prevProducts, response.data]);
      }

      closeModal();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to save product.');
    }
  };

  const handleDeleteProduct = async (productId) => {
    if (!window.confirm('Are you sure you want to delete this product?')) return;

    try {
      await axiosInstance.delete(`/products/products/${productId}/`);
      setProducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));
      toast.success('Product deleted successfully.');
    } catch (error) {
      console.error('Error deleting product:', error);
      toast.error('Failed to delete product.');
    }
  };

  const handleCreateNewBrand = async () => {
    if (!newBrand) {
      toast.error('Brand name cannot be empty.');
      return;
    }
    try {
      const response = await axiosInstance.post('/products/brands/', { name: newBrand });
      setBrands([...brands, response.data]);
      setNewBrand('');
      setIsBrandModalOpen(false);
      toast.success('Brand created successfully.');
    } catch (error) {
      toast.error('Failed to create brand.');
    }
  };
  

  const handleCreateNewChemical = async () => {
    if (!newChemical) {
      toast.error('Chemical name cannot be empty.');
      return;
    }
  
    // Get the selected category from the formData
    const selectedCategory = formData.category;
  
    if (!selectedCategory) {
      toast.error('Please select a category for the product before creating a chemical.');
      return;
    }
  
    try {
      const response = await axiosInstance.post('/products/chemicals/', { 
        name: newChemical,
        category: selectedCategory, // Send the selected category
      });
      setChemicals([...chemicals, response.data]);
      setNewChemical('');
      setIsChemicalModalOpen(false);
      toast.success('Chemical created successfully.');
    } catch (error) {
      console.error('Failed to create chemical:', error);
      toast.error('Failed to create chemical.');
    }
  };
  
  const filteredChemicals = chemicals.filter(chem =>
    chem.name.toLowerCase().includes(chemicalSearch.toLowerCase())
  );
  
  

  return (
    <div className="container mx-auto p-6 bg-gray-100 min-h-screen">
      <ToastContainer />

      {/* Share Button for Vendor Portfolio */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Product Management</h1>
        <button
          onClick={handleShareVendorPortfolio}
          className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
        >
          <ShareIcon className="h-5 w-5 mr-2" />
          Share Vendor Portfolio
        </button>
      </div>
      <div className="flex justify-end mb-6">
        <button
          onClick={openAddModal}
          className="flex items-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          Add Product
        </button>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">All Products</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-gray-200 text-gray-700">
                <th className="px-4 py-2">Image</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Category</th>
                <th className="px-4 py-2">Brand</th>
                <th className="px-4 py-2">Packing Sizes</th>
                <th className="px-4 py-2">Price</th>
                <th className="px-4 py-2">Stock</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center py-4 text-gray-500">
                    No products found.
                  </td>
                </tr>
              ) : (
                products.map((product) => (
                  <tr key={product.id} className="border-t hover:bg-gray-50 transition-colors">
                    <td className="px-4 py-2">
                      {product.media.length > 0 && (
                        <img
                          src={product.media[0].thumbnail_url}
                          alt={product.name}
                          className="w-16 h-16 object-cover rounded-lg shadow-sm"
                        />
                      )}
                    </td>
                    <td className="px-4 py-2">{product.name}</td>
                    <td className="px-4 py-2">{product.category.name}</td>
                    <td className="px-4 py-2">{product.brand.name}</td>

{/* Packing Sizes */}
              <td className="px-4 py-2">
                {product.packing_sizes.length > 0 ? (
                  product.packing_sizes.map((ps, index) => (
                    <div key={index} className="mb-2 border-b border-gray-300 pb-2">
                      <span className="block text-sm font-semibold text-gray-800">Size: {ps.size}</span>
                    </div>
                  ))
                ) : (
                  <span className="text-gray-500">N/A</span>
                )}
              </td>
              
              {/* Price */}
              <td className="px-4 py-2">
                {product.packing_sizes.length > 0 ? (
                  product.packing_sizes.map((ps, index) => (
                    <div key={index} className="mb-2 border-b border-gray-300 pb-2">
                      <span className="block text-sm">MRP: ₹{ps.price}</span>
                      {ps.discount_price && (
                        <span className="block text-sm text-red-500">Discount: ₹{ps.discount_price}</span>
                      )}
                    </div>
                  ))
                ) : (
                  <span className="text-gray-500">N/A</span>
                )}
              </td>

              {/* Stock */}
              <td className="px-4 py-2">
                {product.packing_sizes.length > 0 ? (
                  product.packing_sizes.map((ps, index) => (
                    <div key={index} className="mb-2 border-b border-gray-300 pb-2">
                      <span className="block text-sm">{ps.stock} units</span>
                    </div>
                  ))
                ) : (
                  <span className="text-gray-500">N/A</span>
                )}
              </td>
                    <td className="px-4 py-2 flex space-x-2">
                      <button
                        onClick={() => openEditModal(product)}
                        className="flex items-center bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-3 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        <PencilIcon className="h-5 w-5 mr-1" />
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteProduct(product.id)}
                        className="flex items-center bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        <TrashIcon className="h-5 w-5 mr-1" />
                        Delete
                      </button>
                      {/* Share Button for Product */}
                      <button
                        onClick={() => handleShareProduct(product)}
                        className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        <ShareIcon className="h-5 w-5 mr-1" />
                        Share
                      </button>

                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>


      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-60"
            leave="ease-in duration-200"
            leaveFrom="opacity-60"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 translate-y-4"
                enterTo="opacity-100 scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 translate-y-0"
                leaveTo="opacity-0 scale-95 translate-y-4"
              >
                <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                      {isEditing ? 'Edit Product' : 'Add Product'}
                    </Dialog.Title>
                    <button onClick={closeModal} className="text-gray-400 hover:text-gray-600">
                      <XMarkIcon className="h-6 w-6" />
                    </button>
                  </div>
                  <form onSubmit={handleFormSubmit} className="pb-24">
                    <div className="mb-4">
                      <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
                        Product Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        placeholder="प्रोडक्ट का नाम"
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label htmlFor="description" className="block text-gray-700 font-medium mb-2">
                        Description<span className="text-red-500">*</span>
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="डोज़ क्या रहेगी, कैसे देना है, किसके साथ मिला या नहीं मिला सकते। कौनसी बीमारी के लिए"
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        rows="4"
                        required
                      ></textarea>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="category" className="block text-gray-700 font-medium mb-2">
                        Category<span className="text-red-500">*</span>
                      </label>
                      <select
                        id="category"
                        name="category"
                        value={formData.category}
                        placeholder="श्रेणी चुनें"
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                      >
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="brand" className="block text-gray-700 font-medium mb-2">
                        Brand<span className="text-red-500">*</span>
                      </label>
                      <select
                        id="brand"
                        name="brand"
                        placeholder="ब्रांड चुनें | न्यू पर क्लिक करके नया ब्रांड डालें"
                        value={formData.brand}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                      >
                        <option value="">Select Brand</option>
                        {brands.map((brand) => (
                          <option key={brand.id} value={brand.id}>
                            {brand.name}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        onClick={() => setIsBrandModalOpen(true)}
                        className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        New
                      </button>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="crops" className="block text-gray-700 font-medium mb-2">
                        Recommended Crops
                      </label>
                      <select
                        id="crops"
                        name="crops"
                        multiple
                        value={selectedCrops}
                        placeholder="कौनसी फसलों के लिए"

                        onChange={handleCropChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        {crops.map((crop) => (
                          <option key={crop.id} value={crop.id}>
                            {crop.name} - {crop.variety} ({crop.season})
                          </option>
                        ))}
                      </select>
                      <p className="text-gray-500 text-sm mt-1">
                        Hold down the Ctrl (Windows) or Command (Mac) button to select multiple options.
                      </p>
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 font-medium mb-2">Packing Sizes</label>
                      {formData.packing_sizes.map((ps, index) => (
                        <div key={index} className="mb-4 border p-4 rounded-lg shadow-md">
                          <div className="mb-2">
                            <label className="block text-gray-700">Size</label>
                            <input
                              type="text"
                              name="size"
                              value={ps.size}
                              placeholder="साइज क्या होगा 100g 1 लीटर ?"
                              onChange={(e) => handlePackingSizeChange(index, e)}
                              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                          </div>
                          <div className="mb-2">
                            <label className="block text-gray-700">MRP</label>
                            <input
                              type="number"
                              name="price"
                              placeholder="MRP ?"
                              value={ps.price}
                              onChange={(e) => handlePackingSizeChange(index, e)}
                              step="0.01"
                              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                          </div>
                          <div className="mb-2">
                            <label className="block text-gray-700">Discount Price</label>
                            <input
                              type="number"
                              name="discount_price"
                              placeholder="आप किस रेट पर बेचोगे ?"
                              value={ps.discount_price}
                              onChange={(e) => handlePackingSizeChange(index, e)}
                              step="0.01"
                              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                          </div>
                          <div className="mb-2">
                            <label className="block text-gray-700">Stock</label>
                            <input
                              type="number"
                              name="stock"
                              placeholder="कितना प्रोडक्ट है ?"
                              value={ps.stock}
                              onChange={(e) => handlePackingSizeChange(index, e)}
                              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => removePackingSize(index)}
                            className="text-red-500 hover:text-red-700 mt-2"
                          >
                            Remove Packing Size
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={addPackingSize}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        Add More Size
                      </button>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="chemicals" className="block text-gray-700 font-medium mb-2">
                        Chemicals
                      </label>
                      <select
                        id="chemicals"
                        name="chemicals"
                        placeholder="टेक्निकल सिलेक्ट करें, न्यू पर क्लिक करके नया टेक्निकल डालें"
                        multiple
                        value={formData.chemicals}
                        onChange={handleChemicalChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        {chemicals.map((chem) => (
                          <option key={chem.id} value={chem.id}>
                            {chem.name}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        onClick={() => setIsChemicalModalOpen(true)}
                        className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        New
                      </button>
                      <p className="text-gray-500 text-sm mt-1">
                        Hold down the Ctrl (Windows) or Command (Mac) button to select multiple options.
                      </p>
                    </div>

                    <div className="mb-4">
                      <label htmlFor="images" className="block text-gray-700 font-medium mb-2">
                        Product Images
                      </label>
                      <input
                        type="file"
                        id="images"
                        name="images"
                        placeholder=" फोटो डालें"
                        multiple
                        onChange={handleFileChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        accept="image/*"
                      />
                      <p className="text-gray-500 text-sm mt-1">
                        You can upload multiple images. Accepted formats: JPG, PNG.
                      </p>
                    </div>

                    <div className="flex justify-end space-x-4 mt-6">
                      <button
                        type="button"
                        onClick={closeModal}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      >
                        {isEditing ? 'Update Product' : 'Add Product'}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Brand Modal */}
      <Transition appear show={isBrandModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsBrandModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-60"
            leave="ease-in duration-200"
            leaveFrom="opacity-60"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 translate-y-4"
                enterTo="opacity-100 scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 translate-y-0"
                leaveTo="opacity-0 scale-95 translate-y-4"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Create New Brand
                  </Dialog.Title>
                  <div className="mt-2">
                    <input
                      type="text"
                      value={newBrand}
                      onChange={(e) => setNewBrand(e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Brand Name"
                    />
                  </div>
                  <div className="mt-4 flex justify-end space-x-4">
                    <button
                      type="button"
                      onClick={handleCreateNewBrand}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      onClick={() => setIsBrandModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Chemical Modal */}
      <Transition appear show={isChemicalModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsChemicalModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-60"
            leave="ease-in duration-200"
            leaveFrom="opacity-60"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 translate-y-4"
                enterTo="opacity-100 scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 translate-y-0"
                leaveTo="opacity-0 scale-95 translate-y-4"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Create New Chemical
                  </Dialog.Title>
                  <div className="mt-2">
                    <input
                      type="text"
                      value={newChemical}
                      onChange={(e) => setNewChemical(e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Chemical Name"
                    />
                  </div>
                  <div className="mt-4 flex justify-end space-x-4">
                    <button
                      type="button"
                      onClick={handleCreateNewChemical}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                      onClick={() => setIsChemicalModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};


export default VProductPage;
