import React, { useState, useEffect, useCallback } from 'react';

const ProductGrid = ({ fetchMoreProducts, products, quantities, handleAddToCart, handleQuantityChange, handleProductClick, selectedPackingSizes, handlePackingSizeChange }) => {
  const [isFetching, setIsFetching] = useState(false);

  // Infinite scrolling effect
  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) {
      return;
    }
    setIsFetching(true);
  }, [isFetching]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreProducts();  // Trigger the parent to fetch more products
    setIsFetching(false);
  }, [isFetching, fetchMoreProducts]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {products.map((product) => (
        <div key={product.id} className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
          <img
            src={product.media[0]?.display_image_url || 'https://via.placeholder.com/150'}
            alt={product.name}
            className="w-full h-48 object-cover mb-4 rounded-lg"
          />
          <h2 className="text-xl font-bold mb-1">{product.name}</h2>
          <p className="text-sm text-gray-600 mb-1">Brand: {product.brand.name}</p>
          <p className="text-sm text-gray-600 mb-1">Category: {product.category.name}</p>
          <p className="text-sm text-gray-600 mb-1">Recommended for: {product.recommended_for_names.join(', ')}</p>

          {product.packing_sizes.length > 0 && (
            <div className="mb-4">
              <label htmlFor={`packing-size-${product.id}`} className="block mb-1 text-sm font-medium text-gray-700">Select Packing Size</label>
              <select
                id={`packing-size-${product.id}`}
                value={selectedPackingSizes[product.id]}
                onChange={(e) => handlePackingSizeChange(e, product.id, parseInt(e.target.value))}
                className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              >
                {product.packing_sizes.map((packingSize) => (
                  <option key={packingSize.id} value={packingSize.id}>
                    {packingSize.size} - ₹{packingSize.discount_price || packingSize.price}
                  </option>
                ))}
              </select>
            </div>
          )}

          {product.packing_sizes.length > 0 && (
            <p className="text-green-600 font-bold mb-2">
              MRP: ₹{product.packing_sizes.find(packingSize => packingSize.id === selectedPackingSizes[product.id])?.price || 'N/A'}, 
              Discount: ₹{product.packing_sizes.find(packingSize => packingSize.id === selectedPackingSizes[product.id])?.discount_price || 'N/A'}
            </p>
          )}

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={(e) => { e.stopPropagation(); handleQuantityChange(product.id, -1); }} className="bg-gray-200 px-2 py-1 rounded-l-md hover:bg-gray-300">-</button>
              <span className="px-4 py-1 bg-white border-t border-b border-gray-200">{quantities[product.id]}</span>
              <button onClick={(e) => { e.stopPropagation(); handleQuantityChange(product.id, 1); }} className="bg-gray-200 px-2 py-1 rounded-r-md hover:bg-gray-300">+</button>
            </div>
            <button onClick={(e) => { e.stopPropagation(); handleAddToCart(product); }} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">Add to Cart</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductGrid;
