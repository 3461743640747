import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VOrderPage = () => {
    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState('');
    
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('/api/orders/');
                setOrders(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error("Error fetching orders", error);
            }
        };
        fetchOrders();
    }, []);

    const handleStatusChange = async (orderId) => {
        try {
            await axios.post(`/api/orders/${orderId}/update_status/`, { status });
            setOrders(orders.map(order => order.id === orderId ? { ...order, status } : order));
        } catch (error) {
            console.error("Error updating status", error);
        }
    };

    const handleTrackingUpdate = async (orderId, trackingNumber) => {
        try {
            await axios.post(`/api/orders/${orderId}/add_tracking/`, { tracking_number: trackingNumber });
            setOrders(orders.map(order => order.id === orderId ? { ...order, tracking_number: trackingNumber } : order));
        } catch (error) {
            console.error("Error updating tracking number", error);
        }
    };

    const handleNotification = async (orderId) => {
        try {
            await axios.post(`/api/orders/${orderId}/send_notification/`);
            alert('Notification sent successfully');
        } catch (error) {
            console.error("Error sending notification", error);
        }
    };

    return (
        <div className="container mx-auto p-6 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-center">Order Management</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-800 text-white">
                        <tr>
                            <th className="py-3 px-6 text-left">Order ID</th>
                            <th className="py-3 px-6 text-left">Retailer</th>
                            <th className="py-3 px-6 text-left">Total</th>
                            <th className="py-3 px-6 text-left">Status</th>
                            <th className="py-3 px-6 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map(order => (
                            <tr key={order.id} className="border-b hover:bg-gray-50">
                                <td className="py-4 px-6">{order.id}</td>
                                <td className="py-4 px-6">{order.retailer}</td>
                                <td className="py-4 px-6">{order.total}</td>
                                <td className="py-4 px-6">
                                    <select 
                                        value={status} 
                                        onChange={(e) => setStatus(e.target.value)} 
                                        className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                    >
                                        {['pending', 'processing', 'shipped', 'delivered', 'cancelled'].map(s => (
                                            <option key={s} value={s}>{s}</option>
                                        ))}
                                    </select>
                                </td>
                                <td className="py-4 px-6 text-center">
                                    <button 
                                        onClick={() => handleStatusChange(order.id)} 
                                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
                                    >
                                        Update Status
                                    </button>
                                    <button 
                                        onClick={() => handleTrackingUpdate(order.id, prompt('Enter tracking number:'))} 
                                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mr-2"
                                    >
                                        Add Tracking
                                    </button>
                                    <button 
                                        onClick={() => handleNotification(order.id)} 
                                        className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                                    >
                                        Send Notification
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VOrderPage;
