import axios from 'axios';
import React, { createContext, useContext, useReducer, useEffect } from 'react';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

// Define action types
const actionTypes = {
  SET_TOKEN: 'SET_TOKEN',
  SET_USER: 'SET_USER',
  SET_LOADING: 'SET_LOADING',
  LOGOUT: 'LOGOUT',
};

// Auth reducer function
const authReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return { ...state, authToken: action.payload, isAuthenticated: !!action.payload };
    case actionTypes.SET_USER:
      return { ...state, user: action.payload };
    case actionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case actionTypes.LOGOUT:
      return { ...state, authToken: null, isAuthenticated: false, user: null };
    default:
      return state;
  }
};

const initialState = {
  authToken: localStorage.getItem('authToken'),
  isAuthenticated: !!localStorage.getItem('authToken'),
  user: null,
  isLoading: true,
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const updateAxiosHeaders = (token) => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        api.defaults.headers.common['Authorization'] = `Token ${token}`;
        fetchUserProfile();
      } else {
        delete axios.defaults.headers.common['Authorization'];
        delete api.defaults.headers.common['Authorization'];
      }
    };

    updateAxiosHeaders(state.authToken);
  }, [state.authToken]);

  const fetchUserProfile = async () => {
    dispatch({ type: actionTypes.SET_LOADING, payload: true });
    try {
      const { data } = await api.get('/account/user/');
      dispatch({ type: actionTypes.SET_USER, payload: data });
    } catch (error) {
      console.error("Failed to fetch user profile", error);
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  };

  const login = async (username, password) => {
    try {
      const response = await api.post('/account/login/', { username, password });
      const {  token, user_type, redirect_url  } = response.data; // Assuming the user_type is included in the response
      localStorage.setItem('authToken', token);
      console.log("Token:", token); 
  
      dispatch({ type: actionTypes.SET_TOKEN, payload: token });
  
      // Return the user type to be used for redirection
      return user_type;
    } catch (error) {
      console.error("Login failed:", error);
      throw error; // Re-throw to handle in UI
    }
  };
  

  const logout = () => {
    localStorage.removeItem('authToken');
    dispatch({ type: actionTypes.LOGOUT });
  };

  return (
    <AuthContext.Provider value={{
      ...state,
      login,
      logout,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
