import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { addToCart, getCartItemCount } from './cartUtils'; // Use the same cart utils
import 'bootstrap/dist/css/bootstrap.min.css';

function ProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPacking, setSelectedPacking] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [cartItemCount, setCartItemCount] = useState(0);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/product/${id}`);
        setProduct(response.data);
        if (response.data.packing_sizes.length > 0) {
          setSelectedPacking(response.data.packing_sizes[0]);
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch product details.');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  useEffect(() => {
    setCartItemCount(getCartItemCount());
  }, []);

  const handleAddToCart = () => {
    if (!selectedPacking) return;
    addToCart(product, selectedPacking, quantity);
    setCartItemCount(getCartItemCount());
    alert(`Added ${quantity} x ${product.name} (${selectedPacking.size}) to cart!`);
  };

  const handlePurchaseNow = () => {
    if (!selectedPacking) {
      alert("Please select a packing size.");
      return;
    }
    addToCart(product, selectedPacking, quantity);
    navigate('/cart');
  };

  const handleThumbnailClick = (index) => {
    setCurrentImage(index);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="bg-gray-100 min-h-screen p-6 flex justify-center items-center">
      <div className="max-w-7xl w-full relative">
        {/* Cart Icon */}
        <div className="absolute top-4 right-4">
          <Link to="/cart">
            <div className="relative">
              <ShoppingCartIcon className="text-3xl text-gray-700" />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                  {cartItemCount}
                </span>
              )}
            </div>
          </Link>
        </div>

        {/* Main Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Image Slider */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            {product.media.length > 0 ? (
              <>
                <Carousel activeIndex={currentImage} onSelect={setCurrentImage}>
                  {product.media.map((image) => (
                    <Carousel.Item key={image.id}>
                      <img
                        src={image.display_image_url}
                        alt={product.name}
                        className="w-full h-auto object-contain rounded-lg"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>

                <div className="flex justify-center mt-4 space-x-3">
                  {product.media.map((image, index) => (
                    <img
                      key={image.id}
                      src={image.display_image_url}
                      alt={`Thumbnail ${index + 1}`}
                      className={`w-16 h-16 object-cover rounded-md cursor-pointer ${
                        index === currentImage ? 'border-4 border-blue-500' : 'border'
                      }`}
                      onClick={() => handleThumbnailClick(index)}
                    />
                  ))}
                </div>
              </>
            ) : (
              <img
                src="https://via.placeholder.com/500x500.png?text=No+Image+Available"
                alt="No Image Available"
                className="w-full h-96 object-contain"
              />
            )}
          </div>

          {/* Product Info */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h1 className="text-4xl font-bold text-gray-900 mb-6">{product.name}</h1>

            {/* Brand */}
            <div className="mb-4">
              <h3 className="font-semibold text-lg text-gray-800">Brand</h3>
              <p className="text-gray-600">{product.brand.name} - {product.brand.description}</p>
            </div>

            {/* Category */}
            <div className="mb-4">
              <h3 className="font-semibold text-lg text-gray-800">Category</h3>
              <p className="text-gray-600">{product.category.name}</p>
            </div>

            {/* Chemicals */}
            {product.chemical_names.length > 0 && (
              <div className="mb-4">
                <h3 className="font-semibold text-lg text-gray-800">Chemicals</h3>
                <ul className="list-disc list-inside">
                  {product.chemical_names.map((chemical, index) => (
                    <li key={index} className="text-gray-600">{chemical}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Recommended Crops */}
            {product.recommended_for_names.length > 0 && (
              <div className="mb-4">
                <h3 className="font-semibold text-lg text-gray-800">Recommended for</h3>
                <ul className="list-disc list-inside">
                  {product.recommended_for_names.map((crop, index) => (
                    <li key={index} className="text-gray-600">{crop}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Packing Sizes */}
            {product.packing_sizes.length > 0 && (
              <div className="mb-4">
                <h3 className="font-semibold text-lg text-gray-800">Select Packing Size</h3>
                <select
                  value={selectedPacking?.id || ''}
                  onChange={(e) => {
                    const packing = product.packing_sizes.find((p) => p.id === Number(e.target.value));
                    setSelectedPacking(packing);
                  }}
                  className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2"
                >
                  {product.packing_sizes.map((packing) => (
                    <option key={packing.id} value={packing.id}>
                      {packing.size} - ₹{packing.price} (Discount: ₹{packing.discount_price})
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Pricing */}
            {selectedPacking && (
              <div className="my-6">
                <h3 className="font-semibold text-lg text-gray-800">Pricing</h3>
                <p className="text-gray-600">MRP: ₹{selectedPacking.price}</p>
                <p className="text-green-600 font-semibold">Discount Price: ₹{selectedPacking.discount_price}</p>
              </div>
            )}

            {/* Quantity Selector */}
            <div className="mb-6">
              <h3 className="font-semibold text-lg text-gray-800">Quantity</h3>
              <div className="flex items-center">
                <button
                  onClick={() => setQuantity(Math.max(1, quantity - 1))}
                  className="bg-gray-200 px-3 py-1 rounded-l-md hover:bg-gray-300"
                >
                  -
                </button>
                <span className="px-4 py-1 bg-white border-t border-b border-gray-200">{quantity}</span>
                <button
                  onClick={() => setQuantity(quantity + 1)}
                  className="bg-gray-200 px-3 py-1 rounded-r-md hover:bg-gray-300"
                >
                  +
                </button>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex space-x-4">
              <button
                onClick={handleAddToCart}
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 font-semibold"
              >
                Add to Cart
              </button>
              <button
                onClick={handlePurchaseNow}
                className="w-full bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 font-semibold"
              >
                Purchase Now
              </button>
            </div>
          </div>
        </div>

        {/* Product Details and Reviews */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
          {/* Product Description */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="font-semibold text-lg text-gray-800 mb-2">Description</h3>
            <p className="text-gray-600">{product.description}</p>
          </div>

          {/* Customer Reviews */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="font-semibold text-lg text-gray-800 mb-2">Customer Reviews</h3>
            <p className="text-gray-600">⭐⭐⭐⭐☆ 4.5/5</p>
            <p className="text-gray-600 mt-2">"Great product! Very effective and good quality."</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductDetail;
