import React, { useState } from 'react';
import { getCart, addToCart } from './cartUtils';
import { TrashIcon } from '@heroicons/react/24/solid'; // Import trash icon from heroicons

function Cart() {
  const [cartItems, setCartItems] = useState(getCart());

  const handleQuantityChange = (product, packingSize, change) => {
    const updatedCart = cartItems.map((item) => {
      if (item.product.id === product.id && item.packingSize?.id === packingSize?.id) {
        const newQuantity = item.quantity + change;
        return {
          ...item,
          quantity: newQuantity > 0 ? newQuantity : 1, // Ensure quantity is at least 1
        };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart)); // Update cart in localStorage
  };

  const handleRemoveItem = (product, packingSize) => {
    const updatedCart = cartItems.filter(
      (item) => !(item.product.id === product.id && item.packingSize?.id === packingSize?.id)
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart)); // Update cart in localStorage
  };

  if (cartItems.length === 0) {
    return <p className="text-center text-gray-500 mt-10">Your cart is empty.</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Your Cart</h1>
      <ul className="divide-y divide-gray-200 space-y-4">
        {cartItems.map((item, index) => (
          <li key={index} className="flex items-center space-x-4 py-4">
            {/* Product Thumbnail */}
            <img
              src={item.product.media[0]?.display_image_url || 'https://via.placeholder.com/150'}
              alt={item.product.name}
              className="w-24 h-24 object-cover rounded-lg"
            />
            <div className="flex-1">
              {/* Product Name, Category, Brand */}
              <h2 className="text-lg font-bold text-gray-800">{item.product.name}</h2>
              <p className="text-sm text-gray-600">Category: {item.product.category.name}</p>
              <p className="text-sm text-gray-600">Brand: {item.product.brand.name}</p>

              {/* Chemical Names */}
              <p className="text-sm text-gray-600">
                Chemicals: {item.product.chemical_names?.length > 0 ? item.product.chemical_names.join(', ') : 'N/A'}
              </p>

              {/* Recommended Crops */}
              <p className="text-sm text-gray-600">
                Recommended for: {item.product.recommended_for_names?.length > 0 ? item.product.recommended_for_names.join(', ') : 'N/A'}
              </p>

              {/* Packing Size, MRP, and Discount Price */}
              {item.packingSize ? (
                <>
                  <p className="text-sm text-gray-600">
                    Packing Size: <span className="font-semibold">{item.packingSize.size}</span>
                  </p>
                  <p className="text-sm text-gray-600">
                    MRP: ₹{item.packingSize.price} | Discount Price: ₹{item.packingSize.discount_price}
                  </p>
                </>
              ) : (
                <p className="text-sm text-red-600">Packing size not available.</p>
              )}
            </div>

            {/* Quantity Controls */}
            <div className="flex items-center space-x-2">
              <button
                onClick={() => handleQuantityChange(item.product, item.packingSize, -1)}
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-3 rounded-md focus:outline-none"
              >
                -
              </button>
              <span className="text-lg text-gray-800">{item.quantity}</span>
              <button
                onClick={() => handleQuantityChange(item.product, item.packingSize, 1)}
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-3 rounded-md focus:outline-none"
              >
                +
              </button>
            </div>

            {/* Remove Item Button */}
            <button
              onClick={() => handleRemoveItem(item.product, item.packingSize)}
              className="ml-4 text-red-500 hover:text-red-600 transition-colors"
            >
              <TrashIcon className="h-6 w-6" />
            </button>

            {/* Price */}
            <div className="text-right ml-4">
              {item.packingSize ? (
                <p className="font-semibold text-xl text-gray-800">
                  ₹{(item.packingSize.discount_price || item.packingSize.price) * item.quantity}
                </p>
              ) : (
                <p className="font-semibold text-lg text-red-600">Price not available</p>
              )}
            </div>
          </li>
        ))}
      </ul>

      <div className="mt-6 text-right">
        <button className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-400">
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
}

export default Cart;
